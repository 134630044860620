import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="text-center py-5 my-5">
      <h1 className="my-4">Page non-trouvé</h1>
      <p className="my-3">Cette page n'existe pas...</p>
      <a href="/" class="btn btn-primary my-3">Retourner sur la page d'accueil</a>
    </div>
  </Layout>
)

export default NotFoundPage
